import classNames from "classnames";
import "./services.scss";

export const Services = () => {
	return (
		<div className={"container"}>
			<h2 className={classNames("subtitle-2", "table-header")}>
				ПРЕЙСКУРАНТ на ортопедические услуги в ИП Горохов М.Ю.
			</h2>
			<table className={"price-table"}>
				<colgroup>
					<col style={{ width: "14%" }} />
					<col style={{ width: "76%" }} />
					<col style={{ width: "8%" }} />
				</colgroup>
				<tbody>
					<tr>
						<td></td>
						<td>
							<p>
								<b> Лечение среднего кариеса</b>
							</p>
						</td>
						<td>
							<p>
								<b>4400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 11.07.027.001</p>
						</td>
						<td>
							<p>Наложение лечебной прокладки</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>Восстановление зуба пломбой, с использованием фотополимеров</p>
						</td>
						<td>
							<p>2220,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b> Лечение глубокого кариеса</b>
							</p>
						</td>
						<td>
							<p>
								<b>5300,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 11.07.027.001</p>
						</td>
						<td>
							<p>Наложение лечебной прокладки</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>Восстановление зуба пломбой, с использованием фотополимеров</p>
						</td>
						<td>
							<p>3000,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b> Лечение кариеса депульпированного зуба</b>
							</p>
						</td>
						<td>
							<p>
								<b>4600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>Восстановление зуба пломбой, с использованием фотополимеров</p>
						</td>
						<td>
							<p>3000,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита в одно посещение однокорневого зуба – с
									восстановлением до 1/2 объема пломбой
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Экстирпация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта (стекловолоконного)</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита в одно посещение двухкорневого зуба – с
									восстановлением до 1/2 объема пломбой
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>11500,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Экстирпация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального стекловолоконного штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала зуба</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита в одно посещение трехкорневого зуба – с
									восстановлением до 1/2 объема пломбой
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>12500,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала зуба</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального стекловолоконного штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита в одно посещение однокорневого зуба – с
									восстановлением более 1/2 объема пломбой
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>11700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Экстирпация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление &gt; 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита в одно посещение двухкорневого зуба – с
									восстановлением более 1/2 объема{" "}
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>12400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Экстирпация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала зуба - 2 канала</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление &gt; 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита в одно посещение трехкорневого зуба – с
									восстановлением более 1/2 объема пломбой
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>13400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Экстирпация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление &gt; 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба в одно посещение под
									вкладку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>8100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку</p>
						</td>
						<td>
							<p>2240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба в одно посещение под
									вкладку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) - 2 канала</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба -2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку - 2 канала</p>
						</td>
						<td>
							<p>3360,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба в одно посещение под
									вкладку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>11100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) - 3 канала</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку - 3 канала</p>
						</td>
						<td>
							<p>3960,00</p>
						</td>
					</tr>

					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба в одно посещение под
									искусственную коронку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3880,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба в одно посещение под
									искусственную коронку{" "}
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) – 2 канала</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба в одно посещения под
									искусственную коронку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>11100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) – 3 канала</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба с восстановлением до 1/2
									объема в два посещения – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>4800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба с восстановлением до 1/2
									объема в два посещения – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9300,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.002</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>0,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба с восстановлением до 1/2
									объема в два посещения – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>5700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба с восстановлением до 1/2
									объема в два посещения – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.002</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала- 2 канала</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба с восстановлением до 1/2
									объема в два посещения – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>6600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 3 канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба с восстановлением до 1/2
									объема в два посещения – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10200,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.002</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала-
								3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>
								Избирательное пришлифовывание твердых тканей зуба, полирование
								зуба
							</p>
						</td>
						<td>
							<p>80,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба с восстановлением &gt; 1/2
									объема в два посещения – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>4800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба с восстановлением &gt; 1/2
									объема в два посещения – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10200,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.002</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>0,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление <b>&gt; 1/2</b> объема зуба пломбой с
								использованием фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба с восстановлением &gt; 1/2
									объема в два посещения – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>5700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба с восстановлением &gt; 1/2
									объема в два посещения – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.002</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала – 2 канала</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление <b>&gt; 1/2</b> объема зуба пломбой с
								использованием фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>

					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба с восстановлением &gt; 1/2
									объема в два посещения – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>6600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 3 канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба с восстановлением &gt; 1/2
									объема в два посещения – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>11100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.002</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта</p>
						</td>
						<td>
							<p>1080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление <b>&gt; 1/2</b> объема зуба пломбой с
								использованием фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>
								Избирательное пришлифовывание твердых тканей зуба, полирование
								зуба
							</p>
						</td>
						<td>
							<p>80,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба в два посещения под
									вкладку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>4800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба в два посещения под
									вкладку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>5900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку</p>
						</td>
						<td>
							<p>2240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба в два посещения под
									вкладку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>5700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) - 2 канала</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба -2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала - 2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба в два посещения под
									вкладку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7200,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку - 2 канала</p>
						</td>
						<td>
							<p>3360,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба в два посещения под
									вкладку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>6600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) - 3 канала</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала - 3 канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба в два посещения под
									вкладку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>8100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку - 3 канала</p>
						</td>
						<td>
							<p>3960,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба в два посещения под
									искусственную коронку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>4800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва)</p>
						</td>
						<td>
							<p>180,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита однокорневого зуба в два посещения под
									искусственную коронку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3880,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба в два посещения под
									искусственную коронку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>5700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) – 2 канала</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита двухкорневого зуба в два посещения под
									искусственную коронку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба в два посещения под
									искусственную коронку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>6600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>Инструментальная и медикаментозная обработка</p>
						</td>
						<td>
							<p>780,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.010</p>
						</td>
						<td>
							<p>Эспирация пульпы (удаление нерва) – 3 канала</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 3 канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение пульпита трехкорневого зуба в два посещения под
									искусственную коронку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>8100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в одно посещение с
									реставрацией до 1/2 объема
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>13100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>

					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в одно посещение с
									реставрацией более 1/2 объема
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>14000,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление боле 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в одно посещение с
									реставрацией до 1/2 объема
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>14700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в одно посещение с
									реставрацией более 1/2 объема
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>15600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление боле 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трёхкорневого зуба в одно посещение с
									реставрацией до 1/2 объема
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>16800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трёхкорневого зуба в одно посещение с
									реставрацией более 1/2 объема
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>17700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление боле 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в одно посещение под
									вкладку{" "}
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку</p>
						</td>
						<td>
							<p>2240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в одно посещение под
									вкладку{" "}
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>12000,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку - 2 канала</p>
						</td>
						<td>
							<p>3360,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трёхкорневого зуба в одно посещение под
									вкладку{" "}
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>14400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку - 3 канала</p>
						</td>
						<td>
							<p>3960,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в одно посещение под
									искусственную коронку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>11200,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в одно посещение под
									искусственную коронку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>12600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трёхкорневого зуба в одно посещение под
									искусственную коронку
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>14400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения с
									реставрацией до 1/2 объема
								</b>{" "}
								<b> – первое посещение</b>
							</p>
						</td>
						<td>
							<p>
								<b>6100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.010</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения с
									реставрацией до 1/2 объема – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1320,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения с
									реставрацией до 1/2 объема – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения с
									реставрацией до 1/2 объема – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала – 2 канала</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1320,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения с
									реставрацией до 1/2 объема – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 3 канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения с
									реставрацией до 1/2 объема – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10400,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								– 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1320,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>3300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения с
									реставрацией более 1/2 объема – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>6100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения с
									реставрацией более 1/2 объема – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10500,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1320,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление более 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения с
									реставрацией более 1/2 объема – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала - 2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения с
									реставрацией более 1/2 объема – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>10800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>1380,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1320,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление более 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения с
									реставрацией более 1/2 объема – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала - 3 канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения с
									реставрацией более 1/2 объема – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>11300,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008</p>
						</td>
						<td>
							<p>Пломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.093</p>
						</td>
						<td>
							<p>Фиксация внутриканального штифта/вкладки</p>
						</td>
						<td>
							<p>1320,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление более 1/2 объема зуба пломбой с использованием
								фотополимеров
							</p>
						</td>
						<td>
							<p>4200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения под
									вкладку - первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>6100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения под
									вкладку - второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>5900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку</p>
						</td>
						<td>
							<p>2240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения под
									вкладку - первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения под
									вкладку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7200,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку</p>
						</td>
						<td>
							<p>3360,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения под
									вкладку - первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения под
									вкладку - второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>8100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.008.004</p>
						</td>
						<td>
							<p>Пломбирование корневого канала под вкладку – 3 канала</p>
						</td>
						<td>
							<p>3960,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения под
									коронку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>6100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала</p>
						</td>
						<td>
							<p>2060,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба</p>
						</td>
						<td>
							<p>1200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала
							</p>
						</td>
						<td>
							<p>540,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита однокорневого зуба в два посещения под
									коронку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7600,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
							</p>
						</td>
						<td>
							<p>1920,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3880,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения под
									коронку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 2 канала</p>
						</td>
						<td>
							<p>3140,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 2 канала</p>
						</td>
						<td>
							<p>1360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала –2 канала
							</p>
						</td>
						<td>
							<p>1100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита двухкорневого зуба в два посещения под
									коронку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>7800,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 2 канала
							</p>
						</td>
						<td>
							<p>2100,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения под
									коронку – первое посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>9900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>40,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.004</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082</p>
						</td>
						<td>
							<p>
								Сошлифование твердых тканей зуба (препарирование кариозной
								полости)
							</p>
						</td>
						<td>
							<p>360,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.002</p>
						</td>
						<td>
							<p>
								Исследование кариозных полостей с использованием
								стоматологического зонда
							</p>
						</td>
						<td>
							<p>200,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.082.001</p>
						</td>
						<td>
							<p>Распломбирование корневого канала - 3 канала</p>
						</td>
						<td>
							<p>4440,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 22.07.004</p>
						</td>
						<td>
							<p>Ультразвуковое расширение корневого канала зуба – 3 канала</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030.003</p>
						</td>
						<td>
							<p>
								Временное пломбирование лекарственным препаратом корневого
								канала – 3 канала
							</p>
						</td>
						<td>
							<p>1580,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002.009</p>
						</td>
						<td>
							<p>Наложение временной пломбы</p>
						</td>
						<td>
							<p>240,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>
									Лечение периодонтита трехкорневого зуба в два посещения под
									коронку – второе посещение
								</b>
							</p>
						</td>
						<td>
							<p>
								<b>8100,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								вторичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.091</p>
						</td>
						<td>
							<p>Снятие временной пломбы</p>
						</td>
						<td>
							<p>480,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.030</p>
						</td>
						<td>
							<p>
								Инструментальная и мекадикаментозная обработка корневого канала
								- 3 канала
							</p>
						</td>
						<td>
							<p>2400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А16.07.031</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбировочными материалами с использованием
								анкерных штифтов
							</p>
						</td>
						<td>
							<p>3900,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>Повышенное стирание зубов – реставрация режущего края</b>
							</p>
						</td>
						<td>
							<p>
								<b>2900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбой – восстановление режущего края 1
								поверхность
							</p>
						</td>
						<td>
							<p>1740,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>Закрытие трем, диастем - 1 поверхность</b>
							</p>
						</td>
						<td>
							<p>
								<b>2900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбой – восстановление режущего края 1
								поверхность
							</p>
						</td>
						<td>
							<p>1700,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>Закрытие трем, диастем - вторая поверхность</b>
							</p>
						</td>
						<td>
							<p>
								<b>1700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>Лечение клиновидного дефекта</b>
							</p>
						</td>
						<td>
							<p>
								<b>5700,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление зуба пломбой – восстановление цвета и формы зуба
								при некариозных поражениях твердых тканей зубов (эрозия,
								клиновидный дефект, гипоплазия)
							</p>
						</td>
						<td>
							<p>4080,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>Реставрация переднего зуба до 1/2 объема </b>
							</p>
						</td>
						<td>
							<p>
								<b>4900,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление до 1/2 объема зуба пломбой с использованием
								фотополимера Enamel – один зуб
							</p>
						</td>
						<td>
							<p>3280,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>Реставрация переднего зуба более 1/2 объема </b>
							</p>
						</td>
						<td>
							<p>
								<b>7300,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.001</p>
						</td>
						<td>
							<p>Осмотр полости рта с помощью дополнительных инструментов</p>
						</td>
						<td>
							<p>120,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.002</p>
						</td>
						<td>
							<p>
								Восстановление <b>&gt;</b> 1/2 объема зуба пломбой с
								использованием фотополимера Enamel – один зуб
							</p>
						</td>
						<td>
							<p>5680,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025</p>
						</td>
						<td>
							<p>Избирательное пришлифовывание твердых тканей зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.025.001</p>
						</td>
						<td>
							<p>Избирательное полирование зуба</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td></td>
						<td>
							<p>
								<b>Лечение пародонтита</b>
							</p>
						</td>
						<td>
							<p>
								<b>4000,00</b>
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.065.001</p>
						</td>
						<td>
							<p>
								Прием (осмотр, консультация) врача-стоматолога терапевта
								первичный
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.001</p>
						</td>
						<td>
							<p>Сбор анамнеза и жалоб при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 01.07.002</p>
						</td>
						<td>
							<p>Визуальное исследование при патологии полости рта</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.003</p>
						</td>
						<td>
							<p>
								Исследование зубодесневых карманов с помощью
								пародонтологического зонда
							</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 02.07.008</p>
						</td>
						<td>
							<p>Определение степени патологической подвижности зубов</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 05.07.001</p>
						</td>
						<td>
							<p>Электроодонтометрия зуба (ЭОД)</p>
						</td>
						<td>
							<p>60,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 06.07.012</p>
						</td>
						<td>
							<p>Радиовизиография</p>
						</td>
						<td>
							<p>400,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>В 01.003.004.005</p>
						</td>
						<td>
							<p>Инфильтрационная анестезия</p>
						</td>
						<td>
							<p>500,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А.16.07.019</p>
						</td>
						<td>
							<p>Временное шинирование - 1 зуб</p>
						</td>
						<td>
							<p>1600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 16.07.026</p>
						</td>
						<td>
							<p>Гингивэктомия - лазером</p>
						</td>
						<td>
							<p>600,00</p>
						</td>
					</tr>
					<tr>
						<td>
							<p>А 11.07.022</p>
						</td>
						<td>
							<p>
								Аппликация лекарственного препарата на слизистую оболочку
								полости рта
							</p>
						</td>
						<td>
							<p>300,00</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};
